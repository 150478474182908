import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import {
  dispatchQuickQuote,
  saveUserDetails,
} from "../../store/actions/userActions";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Cookies from "js-cookie";
const Header = () => {
  const userDetails = useSelector((state) => state.root.userDetails);
  const [showInsuranceDropdown, setShowInsuranceDropdown] = useState(false);
  const [showSupportDropdown, setShowSupportDropdown] = useState(false);
  const [showLoginDropdown, setShowLoginDropdown] = useState(false);
  const [selectedInsuranceProduct, setSelectedInsuranceProduct] =
    useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const toggleLoginModal = () => setLoginModal(!loginModal);
  const [posDetails, setPosDetails] = useState({});
  const [posId, setPosId] = useState("");
  

  // useEffect(() => {
  //   const cookieValue = Cookies.get("userDetails");

  //   if (cookieValue) {
  //     setPosDetails(JSON.parse(cookieValue));
  //     dispatch(saveUserDetails(JSON.parse(cookieValue)));
  //   }

  //   const cookieValue1 = Cookies.get("posId");
  //   setPosId(cookieValue1);
  //   // dispatchQuickQuote("posId", cookieValue1);
  // }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/custom.js";
    script.async = true;
    setTimeout(() => {
      script.onload = () => scriptLoaded();
    }, 1500);
    document.body.appendChild(script);
  }, []);
  const scriptLoaded = () => {
    window.TM_TopNav_Dropdown_Position();
  };

  useEffect(() => {
    setShowInsuranceDropdown(false);
  }, [selectedInsuranceProduct]);

  const logoutUser = () => {
    cookie.remove("postoken");
    cookie.remove("Data");
    cookie.remove("posId");
    cookie.remove("userDetails");
    dispatch(saveUserDetails({}));
    navigate("/");
  };


  const handleInsuranceMouseEnter = () => {
    setShowInsuranceDropdown(true);
  };

  const handleInsuranceMouseLeave = () => {
    setShowInsuranceDropdown(false);
  };

  const handleSupportMouseEnter = () => {
    setShowSupportDropdown(true);
  };

  const handleSupportMouseLeave = () => {
    setShowSupportDropdown(false);
  };

  const handleLogintMouseEnter = () => {
    setShowLoginDropdown(true);
  };

  const handleLoginMouseLeave = () => {
    setShowLoginDropdown(false);
  };

  const setVehicleType = (vehicleType, vehicleModel) => {
    const updatedVType = {
      vehicleModal: vehicleModel,
      vehicleType: vehicleType,
    };
    navigate("/motor-process", {
      state: updatedVType,
    });
    // setVtype(updatedVType);
  };

  const handleclick = () => {
    console.log("becomepos click");
  };

  const posRedirect = (page) => {
    if(window.location.href == 'https://expertcover.in/'){
      if (page === "login") {
        window.location.href = "https://pos.expertcover.in/";
      } else if (page === "profile") {
        window.location.href = "https://pos.expertcover.in/profile";
      } else if (page === "home") {
        window.location.href = "https://pos.expertcover.in/home";
      } else {
        window.location.href = "https://pos.expertcover.in/home";
      }

    }
    else{
      if (page === "login") {
        window.location.href = "https://uatpos.expertcover.in/";
      } else if (page === "profile") {
        window.location.href = "https://uatpos.expertcover.in/profile";
      } else if (page === "home") {
        window.location.href = "https://uatpos.expertcover.in/home";
      } else {
        window.location.href = "https://uatpos.expertcover.in/home";
      }
    }
  };
  return (
    <>
      <header>
        {/* Top header start -----*/}
        <div className="">
          <div className="container-fluid container-lg">
            <div className="row align-items-center">
              <div className="col-lg-2 col-12">
                {/*----- Website Logo -----*/}
                <Link to="/" className="navbar-brand d-lg-block d-none">
                  <img
                    src="/assets/images/ex_new_2.png"
                    alt=""
                    className="w-100"
                  />
                </Link>
              </div>

              <div className="col-lg-7 col-12">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                  <div className="container-fluid justify-content-lg-between">
                    <Link to="/" className="navbar-brand d-lg-none d-block">
                      <img
                        src="/assets/images/ex_new_2.png"
                        alt=""
                      />
                    </Link>
                    {/*----- Nav toggler button on small screens -----*/}
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                    >
                      <span
                        className="navbar-toggler-icon"
                        style={{ width: "36px", height: "100%" }}
                        id="nav-icon"
                      />
                    </button>
                    {/*----- Navbar Navlinks start -----*/}

                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                       

                        <li
                          className="nav-item dropdown"
                          onMouseEnter={handleInsuranceMouseEnter}
                          onMouseLeave={handleInsuranceMouseLeave}
                        >
                          <a
                            className="nav-link dropdown-toggle fs-2"
                            href="#"
                            id="navbarDropdown1"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Insurance Products
                          </a>

                          {/*----- DropDown Menu -----*/}
                          <ul
                            className={`dropdown-menu ${
                              showInsuranceDropdown ? "show" : ""
                            }`}
                            aria-labelledby="navbarDropdown1"
                          >
                            <li className="position-relative py-2">
                              <div
                                className="dropdown-item d-flex justify-content-around "
                                onClick={() => {
                                  setVehicleType("4w", "Pvt Car");
                                  setSelectedInsuranceProduct("4w", "Pvt Car");
                                  setShowInsuranceDropdown(false);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src="/assetss/images/car (2).png"
                                  className=""
                                />
                                <span style={{ marginRight: "2rem" }}>
                                  Car Insurance
                                </span>
                              </div>
                            </li>

                            <li className="position-relative py-2">
                              <div
                                className="dropdown-item  d-flex justify-content-around align-items-center"
                                onClick={() => {
                                  setVehicleType("2w", "MotorBike");
                                  setSelectedInsuranceProduct(
                                    "2w",
                                    "MotorBike"
                                  );
                                  setShowInsuranceDropdown(false);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src="/assetss/images/motorcycle (1).png"
                                  className=""
                                />
                                <span style={{ marginRight: "1rem" }}>
                                  Bike Insurance
                                </span>
                              </div>
                            </li>

                            <li className="position-relative py-2">
                              <div
                                className="dropdown-item  d-flex justify-content-around align-items-center"

                                // onClick={() => {
                                //   setVehicleType("gcv", "Goods Carrying");
                                //   setSelectedInsuranceProduct(
                                //     "gcv",
                                //     "Goods Carrying"
                                //   );
                                //   setShowInsuranceDropdown(false);
                                //   // toggleModal();
                                // }}

                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src="/assetss/images/cargo-truck.png"
                                  className=""
                                />
                                <span style={{ marginRight: "1rem" }}>
                                  GCV Insurance
                                </span>
                              </div>
                            </li>

                            <li className="position-relative py-2">
                              <div
                                className="dropdown-item  d-flex justify-content-around align-items-center"
                                // onClick={() => {
                                //   setVehicleType("pcv", "Passenger Carrying");
                                //   setSelectedInsuranceProduct(
                                //     "pcv",
                                //     "Passenger Carrying"
                                //   );
                                //   setShowInsuranceDropdown(false);
                                // }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src="/assetss/images/frontal-taxi-cab.png"
                                  className=""
                                />
                                <span style={{ marginRight: "1.5rem" }}>
                                  Taxi Insurance
                                </span>
                              </div>
                            </li>
                          </ul>
                        </li>

                        {/*----- Nav link ----*/}
                        <li className="nav-item">
                          <a className="nav-link fs-2" href="#">
                            Raise a Claim
                          </a>
                        </li>

                        {/*----- Nav link ----*/}
                        <li
                          className="nav-item dropdown"
                          onMouseEnter={handleSupportMouseEnter}
                          onMouseLeave={handleSupportMouseLeave}
                        >
                          <a
                            className="nav-link dropdown-toggle fs-2"
                            href="#"
                            id="navbarDropdown2"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Support
                          </a>
                          <ul
                            className={`dropdown-menu ${
                              showSupportDropdown ? "show" : ""
                            }`}
                            aria-labelledby="navbarDropdown2"
                          >
                            <li className="position-relative py-2 ">
                              <Link
                                to="/about-us"
                                className="dropdown-item about-us-support d-flex align-items-center"
                              >
                                <img src="/assetss/images/information-button.png" />
                                <span className="about-us-span">About Us</span>
                              </Link>
                            </li>
                            <li className="position-relative py-2">
                              <Link
                                to="/contact-us"
                                className="dropdown-item contact-support d-flex align-items-center"
                                href="#"
                              >
                                <img
                                  src="/assetss/images/phone.png"
                                />
                                <span>Contact Us</span>
                              </Link>
                            </li>
                            <li className="position-relative py-2">
                              <Link
                                to="/faq"
                                className="dropdown-item faq-support d-flex align-items-center"
                                href="#"
                              >
                                <img src="/assetss/images/faq.png" />
                                <span>FAQ</span>
                              </Link>
                            </li>
                          </ul>
                        </li>

                        {/*----- Nav link ----*/}
                        {userDetails &&
                        Object.keys(userDetails).length === 0 ? (
                          <>
                            <li className="nav-item  d-lg-none d-block">
                              <Link to="/becomepos" className="nav-link fs-2">
                                Become a POS
                              </Link>
                            </li>

                            {/*----- Nav link ----*/}
                            <li className="nav-item d-lg-none d-block">
                              <div
                                onClick={() => toggleLoginModal()}
                                className="nav-link fs-2"
                              >
                                Login
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="nav-item dropdown  d-lg-none d-block">
                              <a
                                className="nav-link dropdown-toggle fs-1"
                                href="#"
                                id="navbarDropdown1"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span
                                  onMouseEnter={handleLogintMouseEnter}
                                  onMouseLeave={handleLoginMouseLeave}
                                >
                                  <img
                                    src="assets/images/myuseerimg.jpeg"
                                    alt=""
                                    className="rounded-circle "
                                    width={35}
                                    style={{ marginRight: "8px" }}
                                  />
                                </span>
                                <span className="d-inline-block">
                                  POS Name -
                                </span>
                                {userDetails?.name &&
                                  userDetails?.name.split(" ")[0]}
                              </a>

                              <ul
                                className={`dropdown-menu ${
                                  showLoginDropdown ? "show" : ""
                                }`}
                                aria-labelledby="navbarDropdown1"
                              >
                                <li className="position-relative py-2">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => navigate("/my-policies")}
                                    className="dropdown-item"
                                  >
                                    My Policies
                                  </a>
                                </li>
                                <li className="position-relative py-2">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => navigate("/change-password")}
                                    className="dropdown-item"
                                  >
                                    Change password
                                  </a>
                                </li>

                                <li className="position-relative py-2">
                                  <Link
                                    to="/"
                                    className="dropdown-item"
                                    onClick={() => logoutUser()}
                                  >
                                    Logout
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </>
                        )}

                        {/*----- Nav link ----*/}
                      </ul>
                    </div>
                    {/*----- Navbar Navlinks End -----*/}
                  </div>
                </nav>
              </div>

              {/*----- Website information container -----*/}
              <div className="col-md-3 col-12 cta-box text-end d-lg-flex d-none">
                {userDetails && Object.keys(userDetails).length == 0 ? (
                  <>
                    {/*----- Become a POS Button -----*/}
                    <Link
                      to="/becomepos"
                      onClick={() => handleclick()}
                      className="becomeposbtn mx-3 fs-3 btn btn-outline-light py-3 px-4 fs-2 ms-auto me-4 d-lg-inline-block d-none"
                    >
                      Become a POS
                    </Link>

                    {/*----- Login Button -----*/}
                    <div
                      onClick={() => toggleLoginModal()}
                      className=" findadvbtn mx-3 fs-3 btn btn-light py-3 px-4 fs-2 d-lg-inline-block d-none"
                    >
                      Login
                    </div>
                  </>
                ) : userDetails?.type === "pos" ? (
                  <>
                    {window.location.href.endsWith("/success-page") && (
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          paddingLeft: "260px",
                        }}
                        onClick={() => posRedirect("home")}
                      >
                        <img
                          src="assetss/images/home-button.png"
                          style={{ maxWidth: "35px" }}
                        ></img>
                      </button>
                    )}
                    <a
                      href="javascript:void(0)"
                      className="userimgcontainer dropdown-toggle ms-auto"
                      data-bs-toggle="dropdown"
                    >
                      <img
                        src="assets/images/myuseerimg.jpeg"
                        alt=""
                        className="w-100 rounded-circle"
                      />
                      <span>
                        {userDetails?.addedPos &&
                          userDetails?.addedPos?.userName}
                      </span>

                      <ul
                        className={`dropdown-menu ${
                          showLoginDropdown ? "show" : ""
                        }`}
                        aria-labelledby="navbarDropdown3"
                      >
                        <li className="position-relative py-2">
                          <p className="dropdown-item m-0 bg-transparent text-dark d-block text-truncate py-2">
                            <strong className="d-block">POS Name -</strong>
                            {userDetails?.name}
                          </p>
                        </li>
                        <li className="position-relative py-2">
                          <a
                            href="javascript:void(0)"
                            onClick={() => posRedirect("profile")}
                            className="dropdown-item"
                          >
                            My Profile
                          </a>
                        </li>
                        <li className="position-relative py-2">
                          <a
                            href="javascript:void(0)"
                            onClick={() => logoutUser()}
                            className="dropdown-item"
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href="javascript:void(0)"
                      className="userimgcontainer dropdown-toggle ms-auto"
                      data-bs-toggle="dropdown"
                    >
                      <img
                        src="assets/images/myuseerimg.jpeg"
                        alt=""
                        className="w-100 rounded-circle"
                      />
                      {userDetails?.addedPos && userDetails?.addedPos?.userName}

                      <ul
                        className={`dropdown-menu ${
                          showLoginDropdown ? "show" : ""
                        }`}
                        aria-labelledby="navbarDropdown3"
                      >
                        <li className="position-relative py-2">
                          <p className="dropdown-item m-0 bg-transparent text-dark d-block text-truncate py-2">
                            <strong className="d-block">POS Name -</strong>
                            {userDetails?.name}
                          </p>
                        </li>
                        {/* <li className="position-relative py-2">
                          <a
                            href="javascript:void(0)"
                            onClick={() => navigate("/my-policies")}
                            className="dropdown-item"
                          >
                            My Policies
                          </a>
                        </li>
                        <li className="position-relative py-2">
                          <a
                            href="javascript:void(0)"
                            onClick={() => navigate("/change-password")}
                            className="dropdown-item"
                          >
                            Change Password
                          </a>
                        </li> */}

                        <li className="position-relative py-2">
                          <a
                            href="javascript:void(0)"
                            onClick={() => logoutUser()}
                            className="dropdown-item"
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Top header End -----*/}
      </header>

      {/* Login Modal */}
      <Modal
        toggle={() => toggleLoginModal()}
        isOpen={loginModal}
        size="md"
        backdrop={false}
        className="modal-dialog-centered"
      >
        {/* <ModalHeader>
          <button onClick={() => toggleLoginModal()}>close</button>
        </ModalHeader> */}
        <ModalBody>
          <div className="modal-logo">
            <a href="">
              <img src="/assets/images/ex_new_2.png"></img>
            </a>
          </div>
          <div className="modal_heading w-100 mb-3">
            {/* <h4 className="text-center fs-1 mb-4">Edit Vehicle Details</h4> */}

            <div onClick={() => toggleLoginModal()} class="v2_close false">
              <a href="">
                <img
                  src="/assets/images/delete_icon.svg"
                  title="Close"
                  alt="close"
                />
              </a>
            </div>
          </div>
          <div className="login_img">
            <img src="./assets/images/loginmodal.png"></img>
          </div>
          <div className="loginmodal_btn">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/login")}
            >
              Customer
            </button>
            <button
              className="btn btn-primary"
              onClick={() => posRedirect("login")}
            >
              POSP
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Header;
