import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
// import '../insurance/Quotelist.css'
// import TooltipCommon from "../common/TooltipCommon";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../../../store/actions/userActions";
import {
  Covers,
  POLICY_TYPE,
  VEHICLE_TYPE,
  tpCovers,
  odCovers,
} from "../../../utility/constants";

function QuotelistCover({
  show,
  setModel,
  handleFilteredResult,
  filter,
  setFilter,
}) {
  const dispatch = useDispatch();
  const list = [0, 20, 25, 35, 45, 50];
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState(Covers);

  const [NewPolicyType, setNewPolicyType] = useState("");

  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);


  useEffect(() => {
    localStorage.setItem("filter", JSON.stringify(filter));
  }, [filter]);

  // Handle filter options

  const handleFilterOptions = (cover, item) => {

    setNewPolicyType(cover);
    dispatchQuickQuote("isFiltered", true);
    let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };
    for (let key in filter) {
      newData[key] = filter[key];

      dispatchQuickQuote("AddOns." + key, filter[key]);
    }

    for (let key in filter) {
      newStructureData[key] = filter[key];
      dispatchMotorQuote(`${key}`, filter[key]);
    }

    let newItem = 0;

    newData["PreviousNoClaimBonus"] = newItem;

    dispatch(resetQuickQuoteResults());

    handleFilteredResult(apiRequestQQ, newStructureData);
  };



  const handleChangeFilter = (e) => {
    const { name, checked } = e.target;
    setFilter({ ...filter, [name]: checked });
  };

  const handleChnageValue = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: parseFloat(value) });
  };

  const filteredAddons = (Covers) => {
    if (motorRequest?.vehicleType === VEHICLE_TYPE?.TWO_WHEELER) {
      return Covers.filter((item) => item.value !== "isCngAccessories");
    }
    return Covers;
  };

  const filteredTPCover = filteredAddons(tpCovers);
  const filteredODCover = filteredAddons(odCovers);
  const filteredCoverList = filteredAddons(Covers);

  const addonsToRender =
    motorRequest.newPolicyType === POLICY_TYPE?.THIRDPARTY
      ? filteredTPCover
      : motorRequest.newPolicyType === POLICY_TYPE?.ODONLY
      ? filteredODCover
      : filteredCoverList;

  return (
    <Modal isOpen={show} toggle={toggle} centered backdrop="static" size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="modal-logo">
              <a href="">
                <img src="/assets/images/ex_new_2.png"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-3">

              <div onClick={toggle} class="v2_close false">
                <a>
                  <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
            </div>

            <div className="mb-3">
              <div className="add-ons-outer px-1">
                {
                  addonsToRender.map((item, index) => (
                    <div className="form-check mb-2 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={item.value}
                        style={{ border: "1px solid black" }}
                        name={item.value}
                        checked={filter[item.value]}
                        onChange={(e) => handleChangeFilter(e)}
                        id={item.value}
                      />
                      <label
                        className="form-check-lable d-block"
                        htmlFor={item.value}
                      >
                        {item.label}
                      </label>
                      {item.value !== "isPassengerCover" &&
                        item.value !== "liabilitiesToPaidDriver" &&
                        item.type == "input" &&
                        filter[item.value] == true && (
                          <input
                            type="number"
                            min={item.min}
                            name={item.inputName}
                            value={filter[item.inputName]}
                            onChange={(e) => handleChnageValue(e, item.value)}
                            style={{ padding: "5px 12px", borderRadius: "6px" }}
                          />
                        )}
                      {item.value === "isPassengerCover" &&
                        item.type == "input" &&
                        filter[item.value] == true && (
                          <>
                            <select
                              className=""
                              style={{
                                padding: "8px 12px",
                                width: "100%",
                                border: "1px solid lightgray",
                                fontSize: "14px",
                                borderRadius: "6px",
                              }}
                              value={filter[item.inputName]}
                              onChange={(e) => handleChnageValue(e)}
                              name={item.inputName}
                            >
                              {Object.entries(item.options).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        )}
                      {item.value === "liabilitiesToPaidDriver" &&
                        item.type == "input" &&
                        filter[item.value] == true && (
                          <>
                            <select
                              className=""
                              style={{
                                padding: "8px 12px",
                                width: "100%",
                                border: "1px solid lightgray",
                                fontSize: "14px",
                                borderRadius: "6px",
                              }}
                              value={filter[item.inputName]}
                              onChange={(e) => handleChnageValue(e)}
                              name={item.inputName}
                            >
                              {Object.entries(item.options).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        )}
                    </div>
                  ))
                }
              </div>
            </div>
            <div class="v2_btngrp">
              <div class="wrapper appliedno">
                <div class="bold fs-2">
                  (
                  {
                    Object.values(filter).filter((value) => value === true)
                      .length
                  }
                  ) Applied
                </div>{" "}
                <div class="match fs-4">Matching with your criteria</div>
              </div>
              <button
                type="submit"
                name="submitBtn"
                class="button bold font-13"
                style={{ width: "30%" }}
                onClick={() => {
                  handleFilterOptions("", "");
                  toggle();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistCover;
