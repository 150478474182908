import React, { useEffect } from 'react'
import Layout from '../../../common/Layout'
import { TabContent, TabPane } from 'reactstrap'
import {
  getFiancierBankData,
  getStates,
  getVehiclePincode,
  getVehiclePreviousInsurer,
  getVehicleRto
} from '../../../Services/masterServices'
import OwnerDetails from '../../../common/ProposalPage/OwnerDetails'
import VehicleDetails from '../../../common/ProposalPage/VehicleDetails'
import NomineeDetails from '../../../common/ProposalPage/NomineeDetails'
import PreviousPolicy from '../../../common/ProposalPage/PreviousPolicy'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { PreviousInsurer } from '../../../utility/BankNames'
import { useState } from 'react'
import Header from '../../../common/Header'
import { Link, useLocation } from 'react-router-dom'
import { FaPerson,FaCar } from "react-icons/fa6";
import { BsPersonFillAdd } from "react-icons/bs";
import {
  dispatchKycConfig,
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchResetMotorQuote,
  resetQuickQuoteResults,
  resetSelectedPlan,
  selectedPlanAction
} from '../../../../store/actions/userActions'
import KYC from '../KYC'
// import { extractCustomerLeadId } from "../../../utility/constants";
import { getPreviousJourneyDetails } from '../../../common/Homepage/TPApiCall'
import {
  POLICY_TYPE,
  VEHICLE_TYPE,
  extractCustomerLeadId
} from '../../../utility/constants'
const Proposal = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [currentStep, setCurrentStep] = useState(1)
  const toggle = tab => setCurrentStep(tab)
  const [state, setState] = React.useState([])
  const motorRequest = useSelector(state => state.root.motorRequest)

  const [rtoData, setRtoData] = React.useState([])
  const [pincodeData, setPincodeData] = React.useState([])
  const [cityData, setCityData] = useState([])
  const [financierData, setFinancierData] = useState([])
  const [insurerData, setInsurerData] = React.useState([])
  const [scrollY, setScrollY] = useState(0)
  const selectedPlan = useSelector(state => state.root.selectedPlan)
  const apiRequestQQ = useSelector(state => state.root.apiRequestQQ)
  const getData = useSelector(store => store.root.QuickQouteResult)
  const kycConfig = useSelector(state => state.root.kycConfig)
  const [rtoLocation, setRtoLocation] = useState('')
  const [loading, setLoading] = useState(true)
  const [extractUrlCustomerLeadId, setExtractUrlCustomerLeadId] =
    useState(false)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    // dispatchMotorQuote("handlePreviousButton", true);

    // const journeyDetails = {
    //   previousProcessJourney: location?.state?.previousProcessJourney,
    //   stepsData: location?.state?.stepsData,
    // };
    // console.log("location?.state?.kycStatus", location?.state?.kycStatus);

    console.log('location?.pathname', location?.pathname)
    const urlCustomerLeadId = extractCustomerLeadId(location?.pathname)
    console.log('urlCustomerLeadId', urlCustomerLeadId)
    setExtractUrlCustomerLeadId(true)
    dispatchMotorQuote('customerLeadId', urlCustomerLeadId)
    // const now = new Date();
    // // Set the cookie to expire in 2 hours
    // const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
    // setCookie("customerLeadId", urlCustomerLeadId, { expires });

    getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId })
      .then(response => {
        const stepsData = response?.data?.stepsData
        // if (
        //   response?.data?.processStep === "step10" &&
        //   response?.data?.processDiscription === "vehicleDetails" &&
        //   stepsData?.step10?.vehicleDetails?.proposalStatus ===
        //     caseStatus?.proposalSuccess
        // ) {
        //   navigate(`/checkout/${response?.data?.customerLeadId}`);
        // } else
        if (
          (response?.data?.processStep === 'step8' &&
            response?.data?.processDiscription === 'kycAndUserDetails') ||
          (response?.data?.processStep === 'step9' &&
            response?.data?.processDiscription === 'nomineeDetails') ||
          (response?.data?.processStep === 'step10' &&
            response?.data?.processDiscription === 'vehicleDetails')
        ) {
          updateJourneyData(
            stepsData,
            response?.data?.processStep,
            response?.data?.processDiscription
          )
        } else if (
          response?.data?.processStep === 'step13' &&
          response?.data?.processDiscription === 'pdfGeneration'
        ) {
          const caseId = stepsData?.step13?.motorRequest?.caseId
          for (let key in stepsData?.step13?.motorRequest) {
            dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key])
          }
          navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`)
        } else if (
          response?.data?.processStep === 'step7' &&
          response?.data?.processDiscription === 'quotation'
        ) {
          if (stepsData?.step12) {
            const caseId = stepsData?.step12?.motorRequest?.caseId
            for (let key in stepsData?.step12?.motorRequest) {
              dispatchMotorQuote(`${key}`, stepsData?.step12?.motorRequest[key])
            }
            navigate(`/checkout/${urlCustomerLeadId}`)
          } else if (stepsData?.step11) {
            const caseId = stepsData?.step11?.motorRequest?.caseId
            for (let key in stepsData?.step11?.motorRequest) {
              dispatchMotorQuote(`${key}`, stepsData?.step11?.motorRequest[key])
            }
            navigate(`/checkout/${urlCustomerLeadId}`)
          } else if (stepsData?.step8) {
            if (stepsData?.step7?.motorRequest?.kycStatus === 'completed') {
              for (let key in stepsData?.step8?.kycAndUserDetails) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step8?.kycAndUserDetails[key]
                )
              }
            }
            // dispatch(selectedPlanAction(stepsData?.step8?.planDetails));
            for (let key in stepsData?.step8?.motorRequest) {
              if (key === 'kycStatus') continue
              dispatchMotorQuote(`${key}`, stepsData?.step8?.motorRequest[key])
            }
            // navigate(`/quotelist/${urlCustomerLeadId}`);

            navigate(`/proposal/${urlCustomerLeadId}`)
          }
          // else if (stepsData?.step7) {
          //   for (let key in stepsData?.step7?.motorRequest) {
          //     dispatchMotorQuote(
          //       `${key}`,
          //       stepsData?.step7?.motorRequest[key]
          //     );
          //   }
          //   navigate(`/quotelist/${urlCustomerLeadId}`);
          // }
          // else {
          //   for (let key in stepsData?.step7?.motorRequest) {
          //     dispatchMotorQuote(
          //       `${key}`,
          //       stepsData?.step7?.motorRequest[key]
          //     );
          //   }
          //   navigate(`/quotelist/${urlCustomerLeadId}`);
          // }
        }
      })
      .catch(error => {
        console.error('Error fetching previous journey details:', error)
      })

    // if (
    //   motorRequest?.businessType === "new" ||
    //   motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY ||
    //   motorRequest?.isOwnerChanged
    // ) {
    //   motorQuotation(motorRequest);
    // }
  }, [])

  useEffect(() => {
    // getStates()
    //   .then(response => {
    //     if (response.state === true) {
    //       let i = 0;
    //       let arr = []
    //       while (i < response.data.length) {
    //         arr.push({
    //           value: response.data[i].Digit_Code,
    //           label: response.data[i].State_Name,
    //         })
    //         i++
    //       }
    //       setState(arr);
    //     }
    //   }).catch(err => console.log(err));
    console.log('getData', getData)
    setScrollY(window.scrollY)
    getVehiclePincode()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          let arr1 = []
          let j = 0
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + ' - ' + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City
            })

            i++
          }
          while (j < response.data.length) {
            arr1.push({
              value: response.data[j].Pin_Code,
              label: response.data[j].District,
              District: response.data[j].District,
              City: response.data[j].City
            })

            j++
          }
          setPincodeData(arr)
          setCityData(arr1)
        }
      })
      .catch(err => console.log(err))

    getFiancierBankData()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].bank_name,
              label: response.data[i].bank_name,
              HDFCId: response.data[i].HDFCId
            })

            i++
          }
          setFinancierData(arr)
        }
      })
      .catch(err => console.log(err))

    getVehiclePreviousInsurer().then(response => {
      if (response.status === true) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.prevInsurerId,
            insurerId: item?.prevInsurerId
          })
          i++
        }
        setInsurerData(arr)
      }
    })
    getVehicleRto().then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]

          arr.push({
            label: item.registered_city_name + ' (' + item.RTO_Code + ')',
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id
          })
          i++
        }
        console.log(arr, 'arrrrrrrrrrr')
        setRtoData(arr)

        setRtoLocation(
          arr.filter(option => option.value === apiRequestQQ.RtoCode)
        )
      }
    })
  }, [])

  // useEffect(() => {
  //   if (rtoData.length > 0) {
  //     console.log("RTOOOOOOOO", rtoData);
  //     // setRtoLocation(rtoData);
  //     setRtoLocation(rtoData);
  //   }f
  // }, [rtoData]);

  const updateJourneyData = (stepsData, processStep, processDiscription) => {
    try {
      console.log('stepsData?.step8?.kycConfig', stepsData?.step8?.kycConfig)
      dispatchKycConfig(stepsData?.step8?.kycConfig)
      dispatchProcessFlowMotor('step8', stepsData?.step8)

      for (let key in stepsData?.step8?.motorRequest) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.motorRequest[key])
      }

      for (let key in stepsData?.step8?.kycAndUserDetails) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.kycAndUserDetails[key])
      }

      dispatch(selectedPlanAction(stepsData?.step8?.planDetails))
      dispatchKycConfig(stepsData?.step8?.kycConfig)

      if (stepsData?.step8?.kycAndUserDetails?.addressLine1) {
        if (stepsData.step8.planDetails.PersonalAccident) {
          if (
            processStep === 'step9' &&
            processDiscription === 'nomineeDetails'
          ) {
            for (let key in stepsData?.step9?.motorRequest) {
              if (stepsData?.step9?.motorRequest.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step9?.motorRequest[key]
                )
              }
            }
            for (let key in stepsData?.step9?.nomineeDetails) {
              if (stepsData?.step9?.nomineeDetails.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step9?.nomineeDetails[key]
                )
              }
            }
            setCurrentStep(3)
          } else if (
            processStep === 'step10' &&
            processDiscription === 'vehicleDetails'
          ) {
            for (let key in stepsData?.step10?.motorRequest) {
              if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step10?.motorRequest[key]
                )
              }
            }
            for (let key in stepsData?.step10?.vehicleDetails) {
              if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step10?.vehicleDetails[key]
                )
              }
            }
            setCurrentStep(3)
          } else {
            setCurrentStep(2)
          }
        } else if (
          processStep === 'step10' &&
          processDiscription === 'vehicleDetails'
        ) {
          for (let key in stepsData?.step10?.motorRequest) {
            if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
              dispatchMotorQuote(`${key}`, stepsData?.step10?.motorRequest[key])
            }
          }
          dispatch(selectedPlanAction(stepsData?.step8?.planDetails))
          for (let key in stepsData?.step10?.vehicleDetails) {
            if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
              dispatchMotorQuote(
                `${key}`,
                stepsData?.step10?.vehicleDetails[key]
              )
            }
          }
          setCurrentStep(3)
        } else {
          setCurrentStep(3)
        }
      } else if (
        processStep === 'step13' &&
        processDiscription === 'pdfGeneration'
      ) {
        const caseId = stepsData?.step13?.motorRequest?.caseId
        for (let key in stepsData?.step13?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key])
        }
        navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`)
      }
    } catch (error) {
      console.error('Error fetching previous journey details:', error)
    }
  }

  useEffect(() => {}, [rtoData])

  const getPreviousPolicyName = () => {
    let index = PreviousInsurer.findIndex(
      item => item.Digit_Code == apiRequestQQ.PreInsurerCode
    )
    if (index > -1) {
      return PreviousInsurer[index].Name
    } else {
      return 'N/A'
    }
  }

  // useEffect(() => {
  //   dispatchMotorQuote("handlePreviousButton", true);
  //   const journeyDetails = {
  //     previousProcessJourney: location?.state?.previousProcessJourney,
  //     stepsData: location?.state?.stepsData,
  //   };
  //   console.log("location?.state?.kycStatus", location?.state?.kycStatus);

  //   if (journeyDetails?.previousProcessJourney && journeyDetails?.stepsData) {
  //     updateJourneyData(
  //       journeyDetails?.stepsData,
  //       journeyDetails?.previousProcessJourney?.processStep,
  //       journeyDetails?.previousProcessJourney?.processDescription
  //     );
  //   } else {
  //     console.log("location?.pathname", location?.pathname);
  //     const urlCustomerLeadId = extractCustomerLeadId(location?.pathname);
  //     console.log("urlCustomerLeadId", urlCustomerLeadId);
  //     setExtractUrlCustomerLeadId(true);
  //     dispatchMotorQuote("customerLeadId", urlCustomerLeadId);
  //     const now = new Date();
  //     // Set the cookie to expire in 2 hours
  //     const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
  //     setCookie("customerLeadId", urlCustomerLeadId, { expires });

  //     getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId })
  //       .then((response) => {
  //         console.log("getPreviousJourneyDetails response", response);
  //         const stepsData = response?.data?.stepsData;

  //         // if (
  //         //   response?.data?.processStep === "step10" &&
  //         //   response?.data?.processDiscription === "vehicleDetails" &&
  //         //   stepsData?.step10?.vehicleDetails?.proposalStatus ===
  //         //     caseStatus?.proposalSuccess
  //         // ) {
  //         //   navigate(`/checkout/${response?.data?.customerLeadId}`);
  //         // } else
  //         if (
  //           (response?.data?.processStep === "step8" &&
  //             response?.data?.processDiscription === "kycAndUserDetails") ||
  //           (response?.data?.processStep === "step9" &&
  //             response?.data?.processDiscription === "nomineeDetails") ||
  //           (response?.data?.processStep === "step10" &&
  //             response?.data?.processDiscription === "vehicleDetails")
  //         ) {
  //           updateJourneyData(
  //             stepsData,
  //             response?.data?.processStep,
  //             response?.data?.processDiscription
  //           );
  //         } else if (
  //           response?.data?.processStep === "step13" &&
  //           response?.data?.processDiscription === "pdfGeneration"
  //         ) {
  //           const caseId = stepsData?.step13?.motorRequest?.caseId;
  //           for (let key in stepsData?.step13?.motorRequest) {
  //             dispatchMotorQuote(
  //               `${key}`,
  //               stepsData?.step13?.motorRequest[key]
  //             );
  //           }
  //           navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching previous journey details:", error);
  //       });
  //   }

  //   // if (
  //   //   motorRequest?.businessType === "new" ||
  //   //   motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY ||
  //   //   motorRequest?.isOwnerChanged
  //   // ) {
  //   //   motorQuotation(motorRequest);
  //   // }
  // }, []);

  const handleStepClick = (step) => {
    currentStep > step ? setCurrentStep(step) : "";
  };


  return (
    <Layout header2={false}>
      <section className='quotelist'>
        <div className='container-fluid px-lg-5 px-3'>
          {/* <div
            className="Vehicleshortdisc px-3 py-4 pl-4"
            style={{ background: "#F1FDFF" }}
          >
            <div className="row mx-0 px-0 justify-content-between">
              <div className="col-10 d-flex justify-content-between">
                <ul>
                  <li className="mb-0 fs-3 fw-bold text-uppercase">
                    {" "}
                    {motorRequest?.makeName} {motorRequest?.modelName}{" "}
                    {motorRequest?.variantName}
                  </li>
                  <li className="my-3 fs-3">
                    {motorRequest.vehicleType == "4w"
                      ? "Private Car"
                      : "Two Wheeler"}{" "}
                    -{" "}
                    {motorRequest.isVehicleNew
                      ? motorRequest.rtoCode
                      : motorRequest.registrationNumber}
                  </li>
                </ul>
                <ul>
                  <li className="mb-0 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Previous Insurer
                    </strong>{" "}
                    - {getPreviousPolicyName()}
                  </li>
                  <li className="my-3 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Previous Policy Type
                    </strong>{" "}
                    -{" "}
                    {motorRequest?.prevPolicyType
                      ? motorRequest?.prevPolicyType
                      : "N/A"}
                  </li>
                  <li className="mb-0 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Policy Expiry Date
                    </strong>{" "}
                    -{" "}
                    {motorRequest?.prevPolicyEndDate
                      ? moment(
                          motorRequest.prevPolicyEndDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM, YYYY")
                      : "N/A"}
                  </li>
                </ul>
                <ul>
                  <li className="mb-0 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Manufacture Date
                    </strong>{" "}
                    -{" "}
                    {motorRequest?.manufaturingDate
                      ? moment(
                          motorRequest.manufaturingDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM, YYYY")
                      : "N/A"}
                  </li>
                  <li className="my-3 fs-3 text-uppercase">
                    <strong className="text-capitalize">
                      Registration Date
                    </strong>{" "}
                    -{" "}
                    {motorRequest?.registrationDate
                      ? moment(
                          motorRequest.registrationDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM, YYYY")
                      : "N/A"}
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className='becomepos'>
            {/* <div className="container "> */}
            <div className='row '>
              <div className='col-md-12 '>
                <div className='proposal'>
                  <div className='proposal-details d-flex justify-content-between'>
                    <div className='plan-summary col-3'>
                      <div className='plan-details'>
                        <div className='summary-heading'>
                          <h4 className='text-center fs-1 mt-2'>
                            Your Plan Summary
                          </h4>
                          <div class='line'></div>
                          <div className='summary-img mt-3'>
                            <img src={selectedPlan?.Logo} alt='' />
                          </div>
                        </div>
                        <div className='plan-type mt-2'>
                          <div className='plan-type-idv'>
                            <h3>Plan Type</h3>
                            <h3>
                              {`${
                                selectedPlan?.policyType ===
                                POLICY_TYPE?.COMPREHENSIVE
                                  ? 'Comprehensive'
                                  : selectedPlan?.policyType ===
                                    POLICY_TYPE?.THIRDPARTY
                                  ? 'Third Party'
                                  : selectedPlan?.policyType ===
                                    POLICY_TYPE?.ODONLY
                                  ? 'Own Damage'
                                  : selectedPlan?.policyType
                              }`}
                            </h3>
                          </div>
                          <div className='plan-type-idv'>
                            <h3>Net Premium</h3>
                            <h5>
                              ₹
                              {typeof selectedPlan?.NetPremium === 'string'
                                ? Math.round(
                                    selectedPlan?.NetPremium.replace('INR', '')
                                  )
                                : Math.round(selectedPlan?.NetPremium)}
                            </h5>
                          </div>
                          <div className='plan-type-idv'>
                            <h3>Final Premium</h3>
                            <h5>
                              ₹
                              {typeof selectedPlan?.FinalPremium === 'string'
                                ? Math.round(
                                    selectedPlan?.FinalPremium.replace(
                                      'INR',
                                      ''
                                    )
                                  )
                                : Math.round(selectedPlan?.FinalPremium)}
                            </h5>
                          </div>
                          <div class='line'></div>

                          <div className='accordion-item mb-4 mt-2 p-2 border-0'>
                            <h2 className='accordion-header' id='headingOne'>
                              <button
                                className='accordion-button bg-primary text-white text-capitalize fs-2 fw-bold'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#collapseOne'
                                aria-expanded='true'
                                aria-controls='collapseOne'
                              >
                                Applied Addons & Covers
                              </button>
                            </h2>
                            <div
                              id='collapseOne'
                              className='accordion-collapse collapse'
                              aria-labelledby='headingOne'
                              data-bs-parent='#accordionExample'
                            >
                              <div className='accordion-body'>
                                <div className='table-responsive p-3'>
                                  {selectedPlan?.IsElectricalAccessories !=
                                    false && (
                                    <>
                                      <h3>Electrical Accessories Premium</h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}

                                  {selectedPlan?.IsNonElectricalAccessories !=
                                    false && (
                                    <>
                                      <h3>
                                        Non Electrical Accessories Premium
                                      </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.RoadSideAssistance !=
                                    false && (
                                    <>
                                      <h3>Road Side Assistance</h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.EngineProtection != false && (
                                    <>
                                      <h3>Engine Protection</h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.TyreProtection != false && (
                                    <>
                                      <h3>Tyre Protection </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.RimProtection != false && (
                                    <>
                                      <h3>Rim Protection </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.Consumables != false && (
                                    <>
                                      <h3>Consumables </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.PersonalAccident != false && (
                                    <>
                                      <h3>Personal Accident </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.InvoiceCover != false && (
                                    <>
                                      <h3>Invoice Cover </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}

                                  {selectedPlan?.PassengerCover != false && (
                                    <>
                                      <h3>Passenger Cover </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.LossOfPersonalBelongings !=
                                    false && (
                                    <>
                                      <h3>Loss Of PersonalBelongings </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.ZeroDepriciationCover !=
                                    false && (
                                    <>
                                      <h3>Zero DepriciationCover </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.KeyReplacement != false && (
                                    <>
                                      <h3>KeyReplacement </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.NCBProtection != false && (
                                    <>
                                      <h3>No Claim Protection </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                  {selectedPlan?.VoluntaryDeductive !=
                                    false && (
                                    <>
                                      <h3>Voluntary Deductive </h3>
                                      <div class='line-1'></div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='main-proposal-details'>
                      <ul className='nav nav-tabs formsteps d-flex justify-content-start my-5 d-lg-flex d-block '>
                        <li
                          className='nav-item position-relative'
                          role='presentation'
                        >
                          <button
                            className={
                              currentStep === 1
                                ? 'nav-link fs-2 shadow active'
                                : 'nav-link fs-2 shadow'
                            }
                            onClick={() => handleStepClick(1)}
                          >
                            1
                          </button>
                          <small
                            className='proposalButtonOne me-lg-4 mt-lg-2 mt-3 d-block fs-3 fw-bold'
                            id='proposalBtnOne'
                            // style={{ marginLeft: "-20rem" }}
                          >
                            Personal Details
                          </small>
                        </li>
                        {selectedPlan?.insurerId === 10 ||
                        selectedPlan?.PersonalAccident ? (
                          <li className='nav-item position-relative'>
                            <button
                              className={
                                currentStep === 2
                                  ? 'nav-link fs-2 shadow active'
                                  : 'nav-link fs-2 shadow'
                              }
                              onClick={() => handleStepClick(2)}
                            >
                              2
                            </button>
                            <small className='proposalButtonTwo me-lg-4 mt-lg-2 mt-3 d-block fs-3 mb-0 fw-bold'>
                              Nominee Details
                            </small>
                          </li>
                        ) : (
                          <li className='nav-item position-relative'></li>
                        )}

                        <li className='nav-item position-relative'>
                          <button
                            className={
                              currentStep === 3
                                ? 'nav-link no-after fs-2 shadow active'
                                : 'nav-link no-after fs-2 shadow'
                            }
                            onClick={() => handleStepClick(3)}
                          >
                            {selectedPlan?.PersonalAccident ? 3 : 2}
                          </button>
                          <small className=' proposalButtonThree me-lg-4 mt-lg-2 mt-3 d-block fs-3  mb-0 fw-bold'>
                            Vehicle Details
                          </small>
                        </li>
                      </ul>
                      <div class='vehicle-owner'>
                        <div class='owmer-div-1'>
                          <div class='owner-img active'>
                          <FaPerson/>
                          </div>
                          <div class='owner-text active-text'>
                            <h2>Owner Details</h2>
                          </div>
                        </div>
                         {selectedPlan?.insurerId === 10 ||
                          selectedPlan?.PersonalAccident ? (
                            <div class='owmer-div-2'>
                            <div  className={
                            currentStep === 2
                              ? 'owner-img no-after fs-2 active'
                              : 'owner-img no-after fs-2 '
                          }>
                             <BsPersonFillAdd/>
                            </div>
                            <div  className={
                                currentStep === 2
                                  ? 'owner-text fs-2  active'
                                  : 'owner-text fs-2 '
                              }>
                              <h2>Nominee Details</h2>
                            </div>
                          </div>
                          ) : (
                            <></>
                          )}
                        
                        <div class='owmer-div-2'>
                          <div 
                          className={
                            currentStep === 3
                              ? 'owner-img no-after fs-2 active'
                              : 'owner-img no-after fs-2 '
                          }
                          >
                         <FaCar/>
                          </div>
                          <div className={
                              currentStep === 3
                                ? 'owner-text no-after fs-2 active'
                                : 'owner-text no-after fs-2'
                            }>
                            <h2>Vehicle Details</h2>
                          </div>
                        </div>
                      </div>

                      {currentStep === 1 &&
                        (kycConfig?.kycConfig?.kycStep === 'in_proposal' ||
                          kycConfig?.kycConfig?.kycStep === 'before_proposal' ||
                          kycConfig?.kycConfig?.kycStep ===
                            'after_proposal') && (
                          <KYC
                            setActiveTab={setCurrentStep}
                            // setCurrentStep={setActiveTab}
                            kycStatusCheck={
                              extractCustomerLeadId
                                ? true
                                : motorRequest?.customerLeadId
                                ? true
                                : location?.state?.kycStatus
                            }
                            handleStepClick={handleStepClick}
                          />
                        )}

                      {currentStep === 2 && (
                        <NomineeDetails
                          activeTab={currentStep}
                          toggle={tab => toggle(tab)}
                          scrollY={scrollY}
                          setScrollY={setScrollY}
                        />
                      )}

                      {currentStep === 3 && (
                        <VehicleDetails
                          cityData={cityData.reduce(
                            (c, n) =>
                              c.find(el => el.District == n.District)
                                ? c
                                : [...c, n],
                            []
                          )}
                          insurerData={insurerData}
                          financierData={financierData}
                          rtoData={rtoLocation}
                          activeTab={currentStep}
                          toggle={tab => toggle(tab)}
                          scrollY={scrollY}
                          setScrollY={setScrollY}
                        />
                      )}
                    </div>
                    <div className='plan-summary plan-summary-1 col-3'>
                      <div className='plan-details'>
                        <div className='summary-heading'>
                          <h4 className='text-center fs-1 mt-2'>
                            Vehicle Details
                          </h4>
                          <div class='line'></div>
                        </div>
                        <div className='plan-type mt-2'>
                          <div className='text-center fs-3'>
                            (
                            {motorRequest.isVehicleNew
                              ? motorRequest.rtoCode
                              : motorRequest.registrationNumber}
                            )
                          </div>

                          <div className='plan-type-idv mt-2'>
                            <h2 class='sec_heading'>
                              {motorRequest?.vehicleType ===
                              VEHICLE_TYPE.FOUR_WHEELER ? (
                                <img
                                  src='/assetss/images/pvt_car.png'
                                  width={30}
                                />
                              ) : (
                                <img
                                  src='/assetss/images/Bike1.png'
                                  width={30}
                                />
                              )}
                              <span className='vehicle_modal ps-4'>
                                {motorRequest?.makeName}{' '}
                                {motorRequest?.modelName}{' '}
                                {motorRequest?.variantName}
                              </span>
                            </h2>
                          </div>
                          <div className='plan-type-idv mt-2'>
                            <h3> Manufacture Date</h3>
                            <h3>
                              {motorRequest?.manufaturingDate
                                ? moment(
                                    motorRequest.manufaturingDate,
                                    'YYYY-MM-DD'
                                  ).format('DD MMM, YYYY')
                                : 'N/A'}
                            </h3>
                          </div>
                          <div className='plan-type-idv mt-2'>
                            <h3>Registration Date</h3>
                            <h3>
                              {' '}
                              {motorRequest?.registrationDate
                                ? moment(
                                    motorRequest.registrationDate,
                                    'YYYY-MM-DD'
                                  ).format('DD MMM, YYYY')
                                : 'N/A'}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Proposal
